define("discourse/plugins/discourse-graphviz/discourse-markdown/discourse-graphviz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;

  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }

    helper.allowList(["div.graphviz", "div.graphviz.is-loading", "div.graphviz-svg"]);
    helper.registerOptions(function (opts, siteSettings) {
      opts.features.graphviz = siteSettings.discourse_graphviz_enabled;
    });
    helper.registerPlugin(function (md) {
      if (md.options.discourse.features.graphviz) {
        md.block.bbcode.ruler.push("graphviz", {
          tag: "graphviz",
          replace: function replace(state, tagInfo, content) {
            var engines = ["dot", "neato", "circo", "fdp", "osage", "twopi"];
            var token = state.push("html_raw", "", 0);
            var escaped = state.md.utils.escapeHtml(content);
            var inputEngine = state.md.utils.escapeHtml(tagInfo.attrs.engine);
            var engine = engines.includes(inputEngine) ? "data-engine='".concat(inputEngine, "'") : "data-engine='dot'";
            var svgOnly = "";

            if (tagInfo.attrs.svg === "true") {
              svgOnly = " graphviz-svg";
            } else if (tagInfo.attrs.svg === "false") {
              svgOnly = " graphviz-no-svg";
            }

            token.content = "<div class=\"graphviz is-loading".concat(svgOnly, "\" ").concat(engine, ">\n").concat(escaped, "\n</div>\n");
            return true;
          }
        });
      }
    });
  }
});define("discourse/plugins/discourse-graphviz/initializers/discourse-graphviz", ["exports", "discourse/lib/plugin-api", "discourse/lib/load-script", "pretty-text/sanitizer", "@ember/runloop"], function (_exports, _pluginApi, _loadScript, _sanitizer, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "discourse-graphviz",
    renderGraphs: function renderGraphs($containers) {
      var _this = this;

      $containers.each(function (_, container) {
        var $container = $(container); // if the container content has not yet been replaced
        // do nothing

        if (!$container.find("svg").length) {
          _this.renderGraph($container);
        }
      });
    },
    renderGraph: function renderGraph($container) {
      var graphDefinition = $container.text().trim();
      var engine = $container.attr("data-engine");
      var $spinner = $("<div class='spinner tiny'></div>");
      $container.html($spinner);
      (0, _loadScript.default)("/plugins/discourse-graphviz/javascripts/viz-3.0.1.js").then(function () {
        $container.removeClass("is-loading");

        try {
          /* global vizRenderStringSync */
          var svgChart = vizRenderStringSync(graphDefinition, {
            format: "svg",
            engine: engine
          });
          $container.html(svgChart);
        } catch (e) {
          var $error = $("<div class='graph-error'>".concat((0, _sanitizer.escape)(e.message), "</div>"));
          $container.html($error);
        }
      });
    },
    initialize: function initialize(container) {
      var _this2 = this;

      var siteSettings = container.lookup("site-settings:main");

      if (siteSettings.discourse_graphviz_enabled) {
        // TODO: Use discouseDebounce when discourse 2.7 gets released.
        var debounceFunction = _runloop.debounce;

        try {
          debounceFunction = require("discourse-common/lib/debounce").default;
        } catch (_) {}

        (0, _pluginApi.withPluginApi)("0.8.22", function (api) {
          api.decorateCooked(function ($elem) {
            var $graphviz = $elem.find(".graphviz");

            if ($graphviz.length) {
              debounceFunction(_this2, _this2.renderGraphs, $graphviz, 200);
            }
          }, {
            id: "graphviz"
          });
        });
      }
    }
  };
  _exports.default = _default;
});